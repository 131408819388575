//Library Imports
import React from "react";
import styled, { keyframes } from "styled-components";
import { Container, Row, Col, Visible, Hidden } from "react-grid-system";
import { setConfiguration } from "react-grid-system";
import { Fade } from "react-awesome-reveal";

setConfiguration({
  containerWidths: [540, 740, 960, 1240, 1540, 1810], //Container width
});

// CSS Imports
import "./global.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import { PillsTag } from "./PillsTag";
import { TransparentBtn } from "./TransparentBtn";
import { MagicBlackBtn } from "./MagicBlackBtn";
import { OrangePillsBtn } from "./OrangePillsBtn";
import { userTrack } from "../Common";

// Import Images
import YellowArrow from "./images/YellowArrow.svg";
import ArrowLeft from "./images/ArrowLeft.svg";

// Import Videos
import Video2 from "./video/Video2.mp4";
import VideoGif2 from "./video/VideoGif2.gif";
// import VideoGif2 from "./video/compressedgif2.gif";
// import VideoGif2 from "./video/two.webp";


import Image from "next/image";
import getConfig from "next/config";

// CSS-in-JS
const MainWrapper = styled.section`
  margin: 0 auto;
  height: 500px;
  background: #fff;
  margin-bottom: 100px;

  @media (min-width: 320px) and (max-width: 480px) {
    height: 932px;
  }
`;

const HeroImg = styled.div`
  position: relative;
  top: 0px;
  margin-left: 150px;
  width: 400px;
  border: 1px solid #e5e3e0;
  box-shadow: 0 11px 40px -5px rgba(75, 32, 19, 0.19);
  border-radius: 10px;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-left: 0px;
  }
    @media (min-width: 576px) and (max-width: 1400px) {
    width: 650px; 
  }
`;

const Video = styled.video`
  position: relative;
  top: -43px;
  border-radius: 10px;
  border: 1px solid #e5e3e0;
  box-shadow: 0 11px 40px -5px rgba(75, 32, 19, 0.19);
  margin-left: 91px;
  width: 530px;

  @media (min-width: 576px) and (max-width: 1400px) {
    margin-left: 82px;
    width: 510px;
    top:0;
  }

  @media (min-width: 320px) and (max-width: 480px) {
      display: none;
 
  } 
`;

const Box = styled.div`
  margin-bottom: 100px;

  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: 160px;
    margin-bottom: 70px;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size64);
  font-weight: var(--fw400);
  line-height: 83px;
  text-align: left;
  color: var(--text-color-black);
  margin-top: 10px;
  margin-bottom: 10px;
  text-wrap: nowrap;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 51px;
    text-align: center;
  }
`;

const Text = styled.div`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh25);
  text-align: left;
  color: var(--text-color-dark-liver);
  text-wrap: nowrap;

  span {
    font-size: var(--size20);
    font-weight: var(--fw400);
    line-height: var(--lh20);
    text-align: left;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    text-wrap: wrap;
    text-align: center;
  }
`;

const Text1 = styled.div`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh25);
  text-align: left;
  color: var(--text-color-dark-liver);
  text-wrap: nowrap;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 16px;
    text-wrap: wrap;
    line-height: 20px;
  }
`;

const TextWrap = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const ArrowImg = styled.div`
  position: relative;

  @media (min-width: 320px) and (max-width: 480px) {
    top: 10px;
  }
`;

const BottomText = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh25);
  text-align: left;
  color: var(--text-color-dark-liver);

  @media (min-width: 320px) and (max-width: 480px) {
    text-align: center;
  }
`;

const DoubleBtnWrapper = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 40px;
  margin-bottom: 40px;
  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MagicBtnWrapper = styled.div`
  position: relative;
`;

const ExtendedOrangePillsBtn = styled(OrangePillsBtn)`
  position: absolute;
  right: 5px;
  top: -21px;
`;

const ArrowWrapper = styled.div`
  position: relative;
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;

const moveArrow = keyframes`
  0%, 100% {
    right: -74px;
  }
  50% {
    right: -84px;
  }
`;

const Img = styled.div`
  position: absolute;
  right: -74px;
  top: -64px;
  animation: ${moveArrow} 1s ease-in-out infinite;
`;

const HeroImgWrapper = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0 auto;
    width: 300px;
  }
`;

const CenterPills = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    justify-content: center;
    display: flex;
  }
`;

const GreenSection = React.memo(() => {

  const { publicRuntimeConfig } = getConfig();

  const HandleResearchAssistantClick = async () => {
    await userTrack("", "Onclick of Research Assistant", "Platform");
    window.location.href = "/research-assistant/AI-Research-Assistant.html";
  };

  const HandleLearnMoreClick = async () => {
    await userTrack(
      "",
      "Onclick of Learn More for Research Assistant",
      "Platform"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/Research-Assistant" : publicRuntimeConfig.assetPrefix + "/Research-Assistant.html";
  };



  const videoBasePath = publicRuntimeConfig.assetPrefix || '';


  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <Container>
            <Row>
              <Col xl={6} lg={6} sm={6} xs={12}>
                <Box>
                  <CenterPills>
                    <PillsTag
                      as="div"
                      width="250px"
                      height="49px"
                      backgroundcolor="#dcfce7"
                      color="#15803d"
                    >
                      Research <span>Assistant</span>
                    </PillsTag>
                  </CenterPills>
                  <Title>Get AI to work for you!</Title>
                  <Text>
                    Explore any theme with{" "}
                    <span>AI that's purpose built for Business Research.</span>
                  </Text>
                  <TextWrap>
                    <Text1>Choose a research activity</Text1>{" "}
                    <ArrowImg><Image priority={true} alt="YellowArrow" src={YellowArrow} /></ArrowImg>
                    <Text1>Enter topic</Text1>{" "}
                    <ArrowImg><Image priority={true} alt="YellowArrow" src={YellowArrow} /></ArrowImg>
                    <Text1>Get usable results instantly</Text1>
                  </TextWrap>
                  <BottomText>
                    Enrich Your Research with Expert-Validated Results and
                    Comprehensive Sourcing of data.
                  </BottomText>
                  <DoubleBtnWrapper>
                    <MagicBtnWrapper>
                      <MagicBlackBtn
                        onClick={HandleResearchAssistantClick}
                        width="239px"
                        height="56px"
                      >
                        Research Assistant
                      </MagicBlackBtn>
                      <ExtendedOrangePillsBtn>Try Now</ExtendedOrangePillsBtn>
                    </MagicBtnWrapper>
                    <TransparentBtn onClick={HandleLearnMoreClick}>
                      Learn More
                    </TransparentBtn>
                    <ArrowWrapper>
                      <Img><Image alt="ArrowLeft" src={ArrowLeft} ></Image></Img>
                    </ArrowWrapper>
                  </DoubleBtnWrapper>
                </Box>
              </Col>
              <Col xl={6} lg={6} sm={6} xs={12}>
                <Fade duration={1500} fraction={0.5} triggerOnce={true}>
                  <HeroImgWrapper>
                    <Hidden xs>
                      <Video
                        width="100%"
                        height="auto"
                        autoPlay
                        muted
                        loop
                        playsInline
                        preload="metadata"
                      >

                        <source src={videoBasePath + Video2} type="video/mp4" />
                      </Video>
                    </Hidden>




                    <Visible xs>
                      <HeroImg><Image priority={true} src={VideoGif2} style={{ width: "100%", height: "100%" }} alt="gif2-image" /></HeroImg>

                    </Visible>
                  </HeroImgWrapper>
                </Fade>
              </Col>
            </Row>
          </Container>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default GreenSection;
